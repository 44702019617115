<template lang="pug">
v-card
    v-card-title.d-flex.justify-center {{$t('ADD_CAMPAIGN.TITLE')}}
    v-card-text
        v-form(ref='form')
            v-text-field(v-model='addCampaign.name' :label=`$t('ADD_CAMPAIGN.NAME')` :rules="formRules.nameRule" validate-on-blur required="required")
            v-select(v-model='addCampaign.channel' :label=`$t('ADD_CAMPAIGN.CHANNEL')` :items="campaign_channelList" item-text="name" item-value="key" prepend-icon="mdi-bullhorn-outline")
            v-select(v-model='addCampaign.segment' :label=`$t('ADD_CAMPAIGN.SEGMENT')` :items="segmentList" item-text="name" item-value="id" prepend-icon="mdi-chart-pie")
            v-textarea(v-model='addCampaign.description' :label=`$t('ADD_CAMPAIGN.DESCRIPTION')` prepend-icon="mdi-text")
        v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
</template>

<script>
    import Vue from 'vue';
    import campaign_channelList from "@/array/the_campaignChannelList";
    import CampaignDataService from "@/services/CampaignDataService";
    import i18n from '/common/plugins/vue-i18n.js'

    export default Vue.extend({
        props: {
            addCampaign: {
                type: Object,
                required: true,
            },
            segmentList: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                valid: true,
                formRules: {
                    nameRule: [
                        (v) => !!v ||  i18n.t('RULE.RULE_R_CAMPAIGN'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
                    ],
                },
                campaign_channelList: campaign_channelList,
            }
        },
        created() {
            this.addCampaign.channel = this.campaign_channelList[0].key;
        },
        methods: {
            validate() {
                this.$refs.form.validate();
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            onConfirm() {
                if(this.validateForm()) {
                    let newCampaignData = {
                        "name": this.addCampaign.name,
                        "channel": this.addCampaign.channel,
                        "segment": {
                            "id": this.addCampaign.segment
                        },
                        "description": this.addCampaign.description
                    }
                    CampaignDataService.create(newCampaignData)
                    .then(response => {
                        newCampaignData = response.data;
                        this.$emit('emitAddCampaignData', newCampaignData);
                        this.$emit('emitAddCampaignDialog', true);
                    });
                }
            },
            onCancel() {
                this.$emit('emitAddCampaignDialog', false);
            },
        }
    });
</script>