<template lang="pug">
v-card
    v-card-title.d-flex.justify-center {{$t('EDIT_CAMPAIGN.TITLE')}}
    v-card-text
        v-form(ref='form')
            v-text-field(v-model='editCampaign.name' :label=`$t('EDIT_CAMPAIGN.NAME')` :rules="formRules.nameRule" validate-on-blur required="required")
            v-select(v-model='editCampaign.channel' :label=`$t('EDIT_CAMPAIGN.CHANNEL')` :items="campaign_channelList" item-text="name" item-value="key")
            v-select(v-model='editCampaign.segment' :label=`$t('EDIT_CAMPAIGN.SEGMENT')` :items="segmentList" item-text="name" item-value="id" :disabled='has_cam_line')
            v-textarea.textarea-style(v-model='editCampaign.description' :label=`$t('EDIT_CAMPAIGN.DESCRIPTION')`)
        v-card-actions.mt-8.px-0.py-0
            v-row(no-gutters)
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.CONFIRM')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
	import Vue from 'vue';
    import campaign_channelList from "@/array/the_campaignChannelList";
    import CampaignDataService from "@/services/CampaignDataService";
    import SegmentDataService from "@/services/SegmentDataService";
    import i18n from '/common/plugins/vue-i18n.js'
    import errorDialog from '@/components/Dialog/errorDialog'; 

    export default Vue.extend({
        props: {
            editCampaign: {
                type: Object,
                required: true,
            },
            has_cam_line: {
                type: Boolean,
                required: true,
            },
            has_ai_setting: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            errorDialog,
        },
        data() {
            return {
                valid: true,
                formRules: {
                    nameRule: [
						(v) => !!v ||  i18n.t('RULE.RULE_R_CAMPAIGN'),
                        (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
					],
                },
                campaign_channelList: campaign_channelList,
                segmentList: [],
                originData:JSON.parse(JSON.stringify(this.editCampaign)),
                errorDialog: false,
                errorMessage: '',
            }
        },
        created() {
            SegmentDataService.shortList()
            .then(response => {
                this.segmentList = response.data;
            });
        },
        methods: {
            validate() {
                this.$refs.form.validate();
            },
            validateForm(){
                if(this.$refs.form.validate()){
                    return true;
                }
                else{
                    return false;
                }
            },
            onConfirm() {
                if(this.validateForm()) {
                    let editCampaignData = {};
                    if (typeof(this.editCampaign.segment) == "object") {
                        editCampaignData = {
                            "id": this.editCampaign.id,
                            "name": this.editCampaign.name,
                            "channel": this.editCampaign.channel,
                            "segment": this.editCampaign.segment,
                            "description": this.editCampaign.description,
                            "status": this.editCampaign.status,
                            "type": this.editCampaign.type,
                            "end_time": this.editCampaign.end_time,
                            "start_time": this.editCampaign.start_time,
                        }
                    } else {
                        editCampaignData = {
                            "id": this.editCampaign.id,
                            "name": this.editCampaign.name,
                            "channel": this.editCampaign.channel,
                            "segment": {
                                "id": this.editCampaign.segment,
                            },
                            "description": this.editCampaign.description,
                            "status": this.editCampaign.status,
                            "type": this.editCampaign.type,
                            "end_time": this.editCampaign.end_time,
                            "start_time": this.editCampaign.start_time,
                        }
                    }
                    CampaignDataService.edit(editCampaignData)
                    .then(response => {
                        editCampaignData = response.data;
                        this.$emit('emitEditCampaignData', editCampaignData);
                        this.$emit('emitEditCampaignDialog', true);
                    });
                }
            },
            onCancel() {
				this.$emit('emitEditCampaignDialog', false);
			},
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
        },
        watch:{
            "editCampaign.channel": function(){
                if(this.has_ai_setting && this.editCampaign.channel != this.originData.channel)
                {
                    this.showErrorDialog(i18n.t("AL_MAIL.CHANGE_ERROR"));
                }
            },
            "editCampaign.segment": function(){
                if(this.has_ai_setting && this.editCampaign.segment != this.originData.segment)
                {
                    this.showErrorDialog(i18n.t("AL_MAIL.CHANGE_ERROR"));
                }
            },
        }
    });
</script>